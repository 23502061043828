import { v4 as uuidv4 } from "uuid";
import api from "../api";

window.tikTokServerSideAnalytics = {
  // Look at the _segment partial in the layout
  // to see the middleware that calls this method
  track(eventName, properties) {
    const eventId = uuidv4();
    window.ttq.track(eventName, properties, { event_id: eventId });
    const urlParams = new URLSearchParams(window.location.search);
    const ttclid = urlParams.get("ttclid");
    api.post("/tik_tok_s2s/track", {
      event: eventName,
      properties,
      event_id: eventId,
      ttclid
    });
  }
};
